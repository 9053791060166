const footerMenuList = [
  {
    id: 1,
    title: "Racing",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/loretta-lynn-tracker",
        text: "Loretta Lynn Tracker",
      },
      {
        id: 2,
        href: "/races",
        text: "Races",
      },
      {
        id: 3,
        href: "/live-racing",
        text: "Live Racing",
      }
    ],
  },
  {
    id: 2,
    title: "Riders",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/riders/current-champions",
        text: "Current Champions",
      },
      {
        id: 2,
        href: "/riders/trending",
        text: "Trending Riders",
      },
      {
        id: 3,
        href: "/riders/weekly-winners",
        text: "This weeks winners"
      }
    ],
  },
  {
    id: 3,
    title: "Platform",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/platform-status",
        text: "Platform Status",
      },
      {
        id: 2,
        href: "/about-sponsoring",
        text: "Become a Sponsor",
      },
      {
        id: 3,
        href: "/contact-us",
        text: "Contact Us",
      }
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/groups/amateurmoto",
    text: "facebook",
  },
  {
    id: 4,
    href: "https://www.instagram.com/therealamateurmoto/",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com/@minimotomafia",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
