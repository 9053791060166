import React from 'react';
import Link from "next/link";
export const LoginButton = ({
  returnTo = ""
}) => {
  return <Link href={`/api/auth/login?returnTo=${returnTo}`} data-sentry-element="Link" data-sentry-component="LoginButton" data-sentry-source-file="LoginButton.jsx">
      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
        <span>
          Login
        </span>
      </button>
    </Link>;
};