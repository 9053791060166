import React from 'react';
import Link from "next/link";
export const MyProfileButton = () => {
  return <Link href="/my-profile" data-sentry-element="Link" data-sentry-component="MyProfileButton" data-sentry-source-file="MyProfileButton.jsx">
      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
        <span>
          My Profile
        </span>
      </button>
    </Link>;
};