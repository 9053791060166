import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import Script from 'next/script';
import snippet from '../components/new-relic/snippet';
import RouteChangeBusyIndicator from '../components/route-change-busy-indicator';
if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}
const env = process.env.NODE_ENV;
const isDevelopment = env === 'development';
function MyApp({
  Component,
  pageProps
}) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0
  });
  return <UserProvider>
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <UserContext.Provider value={{
            scrollRef: scrollRef
          }}>
              {!isDevelopment && <>
                <script type="text/javascript" dangerouslySetInnerHTML={{
                __html: snippet
              }}>
                </script>
                <Script src="https://www.googletagmanager.com/gtag/js?id=G-H3KT806BPP" />
                <Script id="google-analytics">
                  {`
                 window.dataLayer = window.dataLayer || [];
                 function gtag(){dataLayer.push(arguments);}
                 gtag('js', new Date());
               
                 gtag('config', 'G-H3KT806BPP');
              `}
                </Script>
                <script id="newrelic" type="text/javascript" dangerouslySetInnerHTML={{
                __html: snippet
              }}></script>
              </>}
              {pid === "/login" ? <Component {...pageProps} /> : <Layout>
                  <RouteChangeBusyIndicator />
                  <Component {...pageProps} />
                </Layout>}
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </UserProvider>;
}
export default MyApp;