import React from 'react';
import Link from "next/link";
export const AdminAreaButton = () => {
  return <Link href="/admin" data-sentry-element="Link" data-sentry-component="AdminAreaButton" data-sentry-source-file="AdminAreaButton.jsx">
      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
        <span>
          Admin Area
        </span>
      </button>
    </Link>;
};