"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";
import { SignupButton } from "./SignupButton";
import { AdminAreaButton } from './AdminAreaButton';
import { MyProfileButton } from "./MyProfileButton";
export const NavBarButtons = ({
  setToggle
}) => {
  const {
    user
  } = useUser();
  const rolesKey = 'amateurmoto.com/roles';
  const isAdmin = user && user[rolesKey] && user[rolesKey].length && user[rolesKey].includes('Admin Area');
  return <>
            {!user && <>
                    <li className="group" onClick={() => setToggle(false)}>
                        <SignupButton />
                    </li>

                    <li className="group" onClick={() => setToggle(false)}>
                        <LoginButton />
                    </li>
                </>}
            {isAdmin && <li className="group" onClick={() => setToggle(false)}>
                    <AdminAreaButton />
                </li>}
            {user && <>
                    <li className="group" onClick={() => setToggle(false)}>
                        <MyProfileButton />
                    </li>
                    <li className="group" onClick={() => setToggle(false)}>
                        <LogoutButton />
                    </li>
                </>}
        </>;
};