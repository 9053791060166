import React, { useState } from "react";
import { useRouter } from 'next/router';
import { CircularProgress } from '@mui/material';
const RouteChangeBusyIndicator = () => {
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);
  if (!router || !router.events) {
    return <></>;
  }
  router.events.on('routeChangeStart', () => setIsNavigating(true));
  router.events.on('routeChangeComplete', () => setIsNavigating(false));
  router.events.on('routeChangeError', () => setIsNavigating(false));
  if (!isNavigating) {
    return <></>;
  }
  return <CircularProgress color="inherit" size={100} style={{
    position: 'fixed',
    right: 'calc(50vw - 50px)',
    top: '50vh',
    zIndex: 999999,
    opacity: '.5'
  }} data-sentry-element="CircularProgress" data-sentry-component="RouteChangeBusyIndicator" data-sentry-source-file="route-change-busy-indicator.jsx" />;
};
export default RouteChangeBusyIndicator;